import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'romanNumber'
})
export class RomanNumberPipe implements PipeTransform {
  transform(value: number, safe = true): string {
    const romanNumerals: { [key: number]: string } = {
      1: 'I',
      2: 'II',
      3: 'III',
      4: 'IV',
      5: 'V',
      6: 'VI',
      7: 'VII',
      9: 'IX',
      10: 'X',
      // Add more mappings as needed
    };

    let result = '';
    const sortedKeys = Object.keys(romanNumerals).map(Number).sort((a, b) => b - a);

    for (const key of sortedKeys) {
      while (value >= key) {
        result += romanNumerals[key];
        value -= key;
      }
    }

    return result;
  }

}
