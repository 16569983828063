import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class ReferredByService extends  BaseService{

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.REFERRED_BY(), data);
  }
  totalCount(): Observable<any> {
    return this.getRequest(AppUrl.REFERRED_BY_COUNT());
  }
  get(data): Observable<any> {
    return this.getRequest(AppUrl.REFERRED_BY(), data);
  }
  getStatus(id, data): Observable<any> {
    return this.putRequest(AppUrl.REFERRED_BY_STATUS(id), data);
  }
  detail(uniqueId): Observable<any> {
    return this.getRequest(AppUrl.REFERRED_BY(uniqueId));
  }
  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.REFERRED_BY(id), data);
  }

  delete(id):Observable<any> {
    return this.deleteRequest(AppUrl.REFERRED_BY(id));
  }

}
