import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {DragulaModule} from 'ng2-dragula';

import {NavService} from './services/nav.service';
import {LayoutService} from './services/layout.service';

import {BookmarkComponent} from './components/bookmark/bookmark.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {FeatherIconsComponent} from './components/feather-icons/feather-icons.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {ContentComponent} from './components/layout/content/content.component';
import {FullComponent} from './components/layout/full/full.component';
import {LoaderComponent} from './components/loader/loader.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {DisableKeyPressDirective} from './directives/disable-key-press.directive';
import {OnlyAlphabetsDirective} from './directives/only-alphabets.directive';
import {OnlyNumbersDirective} from './directives/only-numbers.directive';
import {ShowOptionsDirective} from './directives/show-options.directive';
import {IndianNumberPipe} from './pipes/indian-number.pipe';
import {StripHtmlPipe} from './pipes/stripHtml.pipe';
import {SafePipe} from './pipes/safe.pipe';
import {MomentDatePipe} from './pipes/moment-date.pipe';
import { RomanNumberPipe } from './pipes/roman-number.pipe';

@NgModule({

  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    BookmarkComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    IndianNumberPipe,
    StripHtmlPipe,
    SafePipe,
    MomentDatePipe,
    RomanNumberPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    DragulaModule.forRoot(),
    TranslateModule,
  ],
  providers: [
    NavService,
    LayoutService
  ],
  exports: [
    LoaderComponent,
    FeatherIconsComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    TranslateModule,
    IndianNumberPipe,
    RomanNumberPipe,
    StripHtmlPipe,
    SafePipe,
    MomentDatePipe
  ],
})
export class SharedModule {
}
