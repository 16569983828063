import { Component, OnInit } from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StorageService} from '../../shared/services/storage.service';
import {CenterService} from '../../shared/services/center.service';
import {FileService} from '../../shared/services/file.service';
import {AlertService} from '../../shared/services/alert.service';
import {NgbModal, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {ProfileService} from '../../shared/services/profile.service';
import {DataService} from '../../shared/services/data.service';

@Component({
  selector: 'ps-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  password_icon: boolean = false;
  addProfileForm: FormGroup;
  imageChangedEvent: any;
  croppedImage: any;
  croppedImageEvent: any;
  profileImageUploadArea: any = 'assets/images/image-backgrounds/200-200.png';
  profileImage: any

  constructor(config: NgbPaginationConfig,
              private modalService: NgbModal,
              private alertService: AlertService,
              private fileService: FileService,
              private profileService: ProfileService,
              private centerService: CenterService,
              private dataService: DataService,
              private fb: FormBuilder) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  modalType: any;
  user: any;

  ngOnInit(): void {
    this.user = StorageService.getItem('self');
    this.refresh();
  }

  refresh() {
    this.addProfileForm = this.fb.group({
      name: [this.user.name, Validators.required],
      code: [this.user.username, Validators.required],
      username: [this.user.username, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
      contact: [this.user.contact, Validators.required],
    });
    this.getDetails();
  }

  update() {
    this.alertService.showSuccess({title: 'Success', message: 'Profile updated successfully'});
  }

  openLg(content) {
    this.modalType = 'PASSWORD';
    this.modalService.open(content, {size: 'lg'});
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImageEvent = event;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onImageChange(e) {
    this.imageChangedEvent = e;
  }

  openProfileModel(content) {
    this.modalType = 'PROFILE_IMAGE';
    this.modalService.open(content);
  }

  saveProfileImage() {
    const params = {
      'type': 'PROFILE_IMAGE',
      'user_id': this.user.user_id,
      'fileType': 'BASE64'
    };
    this.fileService.fileUpload(params, this.croppedImage).subscribe(data => {
      if (data) {
        this.modalService.dismissAll('');
        this.imageChangedEvent = undefined;
        this.updateProfileImage({'user_id': this.user.user_id, 'profile_image': data['data'].id});
      }
    });
  }

  updateProfileImage(data) {

    this.profileService.updateProfile(this.user.user_id, data).subscribe(data => {
      this.getDetails();
    });
  }

  getDetails() {
    this.centerService.detail(this.user.user_id).subscribe(data => {
      if (data && data['profile_image']) {
        this.profileImage = data['profile_image'].url;
        this.profileImageUploadArea = data['profile_image'].url;
        this.dataService.sendMessage(this.profileImage);
      }
    });
  }
}
