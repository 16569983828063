import { Component, OnInit } from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {AlertService} from "../../shared/services/alert.service";
import {CollegeService} from "../../shared/services/college.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ReferredByService} from "../../shared/services/referred-by.service";

@Component({
  selector: 'ps-referred-by',
  templateUrl: './referred-by.component.html',
  styleUrls: ['./referred-by.component.scss']
})
export class ReferredByComponent implements OnInit {
  referredCount: any = 0;

  constructor(private fb: FormBuilder,
              private router: Router,
              private alertService: AlertService,
              private collegeService: CollegeService,
              private referredByService: ReferredByService,
              public modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.getCollegeCount();
  }

  getCollegeCount() {
    this.referredByService.totalCount().subscribe(data =>{
      this.referredCount = data;
    })
  }


  goTo(url) {
    this.router.navigateByUrl(url);
  }
}
