import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class CenterService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  get(data): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.CENTER(), data);
  }

  count(data): Observable<any> {
    return this.getRequest(AppUrl.CENTER_COUNT(), data);
  }



  getBranches(id, data): Observable<any> {
    return this.getRequest(AppUrl.CENTER_BRANCHES(id), data);
  }

  detail(id): Observable<any> {
    return this.getRequest(AppUrl.CENTER(id));
  }





  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.CENTER(id), data);
  }

  updatePassword(id, data): Observable<any> {
    return this.putRequest(AppUrl.CENTER_PASSWORD(id), data);
  }

}
