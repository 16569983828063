<div class="authentication-main">
  <ng-template #content let-modal>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title font-weight-bold">User Registration OTP</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalService.dismissAll('')"
        >
          ×
        </button>
      </div>
      <div class="modal-body" ngbAutoFocus>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form class="form" novalidate [formGroup]="registrationOtpForm">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <fieldset class="form-group">
                    <input class="form-control" type="number" formControlName="otp"
                           placeholder="Enter OTP">
                    <small *ngIf="submittedOtp && registrationOtpForm.get('otp').hasError('required')"
                           class="text-danger">
                      Field is required.
                    </small>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
            <small>Resend OTP in - </small>
            <small class="text-danger mr-1"
                   *ngIf="timerSixtySeconds > 0">{{timerSixtySeconds}} sec.</small>
            <small class="text-primary cursor-pointer"
                   *ngIf="timerSixtySeconds === 0" (click)="resendOTP()">Resend OTP ?
            </small>
          </div>
        </div>
      </div>
      <!--<div class="modal-footer">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="submitOtp()"
                    [disabled]="submittingOtp">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Submit
            </button>
          </div>
        </div>
      </div>-->
    </div>
  </ng-template>
  <div class="row ">
    <div class="col-lg-5 col-md-12 col-sm-12 text-center">
      <div class="my-5">
        <div class="row">
            <div class="col  pt-5">
              <img src="assets/images/logo/un-logo.png" alt="college Management System" style="height: 200px">
<!--              <img src="assets/images/logo/lelawati.jpeg" alt="University Management System" style="height: 70px">-->
            </div>
        </div>
        <div class="row pt-5 ">
          <div class="col">
            <h2 style="color: #0093FF;">Get more things done with Login platform.</h2>
          </div>
        </div>
        <div class="row pt-5 ">
          <div class="col">
            <p style="font-size: 1.5rem;">"With great power comes great responsibility."</p>
          </div>
        </div>
        <div class="row pt-5 ">
          <div class="col pt-5">
            <img src="assets/images/image-backgrounds/graphic4.svg" alt="first image" class="img-fluid"
                 style="width: 70%;">
          </div>
        </div>
      </div>

    </div>
    <div class="col-lg-7 col-md-12 col-sm-12  " style="background-color: #1c91f81f; padding-top: 100px;">
      <div class="auth-innerright" >
        <div class="authentication-box">
          <div class="mt-4">
            <div class="card-body px-0" style="box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;">
              <div class="text-left">
                <img src="assets/images/favicon.jpg" alt="college Management System" style="height: 60px; border-radius: 50%; padding-left: 0.7rem">
              </div>
              <div class="cont text-center" [ngClass]="{'s--signup': signup}">
                  <form class="theme-form text-center" [formGroup]="loginForm">
                  <!--  <div class="text-left">
                      <img src="assets/images/favicon.jpg" alt="college Management System" style="height: 60px; border-radius: 50%;">
                    </div>-->
                        <h1 class="main-text" style="color: black;">Sign In</h1>
                        <h5 class="main-text" style="color: black; font-weight: 300;" >Sign In to your account and start exploring</h5>
                    <div class="form-group text-left mt-30px">
                      <label class="col-form-label pt-0" style="font-size: 1.2rem;">Username</label>
                      <input class="form-control " type="text" placeholder="volmint"
                             formControlName="username"/>
                    </div>
                    <div class="form-group text-left">
                      <label class="col-form-label" style="font-size: 1.2rem;">Password</label>
                      <input class="form-control border-radius-0" type="password" placeholder="*******"
                             formControlName="password"/>
                    </div>
                    <div class="checkbox p-0 text-left">
                      <input id="checkbox1" type="checkbox">
                      <label for="checkbox1" class="f_12">
                        <small style="font-size: 1.2rem;">Remember me</small>
                      </label>
                    </div>
                    <div class="form-group text-left  mt-55px">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <small class="col-form-label f_12 cursor-pointer" style="font-size: 1.2rem;">Forgot Password ?</small>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <small class="col-form-label f_12 cursor-pointer" style="font-size: 1.2rem;">Forgot Username ?</small>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-row mt-3 mb-0">
                      <button class="btn btn-primary btn-block" style="padding: 15px;font-size: 1.2rem;" type="submit" (click)="signIn()">Sign In</button>
                    </div>
                    <div class="social mt-3 d-block d-md-none height-150px">
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col text-left ml-3" style="background-color: #336ceb69">
   <p class="py-2" style="font-size: 1.5em; font-weight: 300;">© {{date}} by Volmint Technologies Pvt Ltd </p>
    </div>
  </div>
</div>
