<div class="container-fluid">
  <div class="card general-widget">
    <div class="card-header no-border padding-15">
      <ul class="creative-dots">
        <li class="bg-primary big-dot"></li>
        <li class="bg-secondary semi-big-dot"></li>
        <li class="bg-warning medium-dot"></li>
        <li class="bg-info semi-medium-dot"></li>
        <li class="bg-secondary semi-small-dot"></li>
        <li class="bg-primary small-dot"></li>
      </ul>
      <div class="row">
        <div class="col-12">
          <h4 class="m-l-50 m-t-10">
            Colleges - Home
          </h4>
        </div>
      </div>
    </div>

    <div class="card-body m-t-20 padding-15">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 xl-100 box-col-12">
          <div class="card">
            <div class="card-header p-20 no-border">
              <div class="row">
                <div class="col-6">
                  <h5 class="m-l-50 "> College Report</h5>
                  <ul class="creative-dots">
                    <li class="bg-primary big-dot"></li>
                    <li class="bg-secondary semi-big-dot"></li>
                    <li class="bg-warning medium-dot"></li>
                    <li class="bg-info semi-medium-dot"></li>
                    <li class="bg-secondary semi-small-dot"></li>
                    <li class="bg-primary small-dot"></li>
                  </ul>
                </div>
                <div class="col-6">
                  <span class="badge badge-primary pull-right m-r-20 text-white cursor-pointer"
                        (click)="goTo('/college/list-college')">View All</span>
                </div>
              </div>
            </div>
            <div class="card-block row p-20">
              <div class="col-sm-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-sm-6 box-col-6 xl-50 col-xl-3">
                    <div class="card o-hidden">
                      <div class="b-r-4 card-body">
                        <div class="media static-top-widget">
                          <div class="align-self-center text-center color-cyan">
                            <app-feather-icons [icon]="'calendar'"></app-feather-icons>
                          </div>
                          <div class="media-body"><span class="m-0 ">Total Colleges</span>
                            <h4 class="mb-0 counter">{{collegeCount | IndianNumber}}</h4>
                            <i class="icon-bg" data-feather="users"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

