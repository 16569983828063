import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ps-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss']
})
export class IssuesComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {

  }

}
