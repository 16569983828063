<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-xl-12">
      <div class="card  shadow-0 border ">
        <div class="card-body">
          <div class="row p-50px">
            <div class="col-sm-12 col-xl-12 text-center">
              <h6 class="mt-15px">No data to show</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
